import React from 'react'
import './Title.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Title = () => {
   useEffect(()=>{
    AOS.init({
      duration:2000
    });
  },[]);
  return (
    <div className='title' data-aos='fade-up'>
      <p>One of Our products</p>
      <h2>in technique resistance</h2>
    </div>
  )
}

export default Title
