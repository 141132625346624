import React from 'react'
import '../App.css'
import Hero from '../Components/Hero/Hero'
import Programs from '../Components/Programms/Programs'
import Title from '../Components/Title/Title'
import { Textimg } from '../Components/Textimg/Textimg'
import Contacts from '../Components/Contact/Contacts'
// import Testimonials from '../Components/Testimonials/Testimonials'

export const Accueil = () => {
  return (
    <div className='Accueilb'>
<Hero/>
<Title/>
<Programs/>
{/* <Testimonials/> */}
<Textimg/>
{/* <Contacts/> */}
    </div>
  )
}
