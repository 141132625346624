import React, { useState } from 'react';
import './Contacts.css';
import msg_icon from '../../Assets/msg-icon.png';
import mail_icon from '../../Assets/mail-icon.png';
import phone_icon from '../../Assets/phone-icon.png';
import location_icon from '../../Assets/location-icon.png';
import white_arrow from '../../Assets/white-arrow.png';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'


const Contacts = () => {
  useEffect(()=>{
    AOS.init({
      duration:2000
    });
  },[]);
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "be87309b-d4da-43af-849f-1ff592ea4d5a");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
    
  };

  return (
    <div className='contact' data-aos='fade-in'>
      <div className="contact-col">
        <h3 className='titlecontact'>ENVOYER-NOUS UN MESSAGE <img src={msg_icon} alt="Message Icon" /></h3>
        <p>Feel free to reach out through the contact form or find our contact information below. Your feedback, questions, and suggestions are important to us as we strive to provide exceptional service to our university community.</p>
        <ul>
          <li><img className='icons' src={mail_icon} alt="Email Icon" />Techresistance2@gmail.com</li>
          <li><img className='icons' src={phone_icon} alt="Phone Icon" />+212 699 414 706 / +212 661 234 978</li>
          <li><img className='icons' src={location_icon} alt="Location Icon" />Lot 14-Lotissement Zineb - Tit Mellil<br/> - Casablanca (Maroc)</li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label htmlFor="name">votre nom</label>
          <input className='inputanswer' type="text" id="name" name='name' placeholder='votre nom et prenom' required />
          <label htmlFor="phone">numero de telephone</label>
          <input className='inputanswer' type="tel" id="phone" name='phone' placeholder='numero de telephone' required />
          <label htmlFor="message">entrez votre message</label>
          <textarea className='inputanswer' id="message" name="message" rows="6" placeholder='entrez votre message' required></textarea>

<button class="button">

  
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
  </svg>

  
  <div class="text">
    Button
  </div>

</button>

        </form>
        <span id='okan'>{result}</span>
      </div>
    </div>
  );
}

export default Contacts;
