import React from 'react'
import './Programs.css'
import resistance1 from '../../Assets/resistance1.png'
import resistance2 from '../../Assets/resistance2.png'
import resistance3 from '../../Assets/resistance3.png'
import resistance4 from '../../Assets/resistance4.png'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import AOS from 'aos'
const Programs = () => {
  return (
    <div className='programs'>
      <div className="program" data-aos='fade-right'>
        <img src={resistance1} alt="" />
        <div className="caption">
            {/* <img src={program_icon_1} alt="" /> */}
            <p>Colliers</p>
        </div>
      </div>
      <div className="program" data-aos='fade-right'>
        <img src={resistance2} alt="" />
        <div className="caption">
            {/* <img src={program_icon_2} alt="" /> */}
            <p>Resistance</p>
        </div>
      </div>
      <div className="program" data-aos='fade-right'>
        <img src={resistance3} alt="" />
        <div className="caption">
            {/* <img src={program_icon_3} alt="" /> */}
            <p>Cartouches</p>
        </div>
      </div>
      <div className="program" data-aos='fade-right'>
        <img src={resistance4} alt="" />
        <div className="caption">
            {/* <img src={program_icon_1} alt="" /> */}
            <p>Grillage</p>
        </div>
      </div>
    </div>
  )
}

export default Programs
