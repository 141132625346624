import Routee from "./Pages/Routee";
// import './index.css'
import './App.css'

// import './App.css'
function App() {
  return (
    <>
        <Routee/>
    </>
    
  );
}

export default App;
