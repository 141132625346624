import React from 'react'
// import Hystory from '../Components/Noshistoire/Hystory'
// import { Hystory } from '../Components/Noshistoire/Hystory'
import About from '../Components/About/About'

export const Notrehistoire = () => {
  return (
    <div>
      <About/>
    
    </div>
  )
}

