import React from 'react'
import './Titleproduit.css'
import resistance1 from '../../Assets/resistance1.png'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { useEffect } from 'react'

export const Titleproduit = () => {
  useEffect(()=>{
    AOS.init({
      duration:2000
    });
  },[]);
  return (
    <div>

<section id="productedit" data-aos='fade-up'>
        <h2 className='onwan'>FABRICATION DE TOUT TYPE DES RESISTANCES CHAUFFANTES.</h2>
        
        <div class="hayd">
            <p className ='testest'>Nous ferons de notre mieux pour répondre à vos attentes en matière des résistances chauffantes.  </p>
        </div>
        {/* <img className='imgcard' src={resistance1} alt="" /> */}
    </section>
    </div>
  )
}
