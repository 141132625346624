import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from '../Components/Layout'
import { Accueil } from './Accueil';
import { Nosproduit } from './Nosproduit';
import { Notrehistoire } from './Notrehistoire';
import Contacts from '../Components/Contact/Contacts';





function Routee() {
  return (
    <>
    <Router>
      <Routes>
        <Route  path='/' element={<Layout />}>
        <Route  path="/" element={<Accueil />}/>
        <Route  path="/Accueil" element={<Accueil />}/>
        <Route  path="/Nos produit" element={<Nosproduit/>}/>
        <Route  path='/Notre histoire' element={<Notrehistoire/>}/>
        <Route  path='/Contact' element={<Contacts />}/>   
        </Route>
          </Routes>
        </Router>
      </>
   
  )
}

export default Routee 