import React from 'react'
import './Footer.css'
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaPinterest } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";




function Foot() {
  return (
   
    <footer className='foot'>
    <div class="contaainer">
        <div class="footer-content">
            <h5 id='contactus'>Contactez-nous</h5>
            <p>Email:  Techresistance2@gmail.com</p>
            <p>Phone:   +212 661 234 978</p>
            <p>Address:  Lot 14-Lotissement Zineb-Tit Mellil</p>
        </div>
        <div class="footerr-content">
            <h3>Liens rapides</h3>
             <ul class="list">
                <li><a href="">Accueil</a></li>
                <li><a href="">Nos produit</a></li>
                {/* <li><a href="">Services</a></li> */}
                <li><a href="">Notre histoire</a></li>
                <li><a href="">Contact</a></li>
             </ul>
        </div>
        <div class="footer-contentt">
            <h3>Follow Us</h3>
            <ul class="social-icons">
             <li><a href=""><i class="fab fa-facebook"></i></a></li>
             <li><a href=""><i class="fab fa-twitter"></i></a></li>
             <li><a href=""><i class="fab fa-instagram"></i></a></li>
             <li><a href=""><i class="fab fa-linkedin"></i></a></li>
            </ul>
            </div>
            <div className='myicons'>
            <a href=""><FaFacebook /></a>
            <a href=""><AiFillTwitterCircle /></a>
            <a href=""><FaPinterest /></a>
            <a href=""><FaYoutube /></a>


            </div>
    </div>
    <div class="bottom-bar">
        <p>&copy; Developed by zakaria. All rights reserved</p>
    </div>
</footer>

    
)};
export default Foot;

