import { Outlet } from "react-router-dom";
import React from 'react'
import Nav from './Navbar/Nav'
import Footer from "./Footer/Footer";


function Layout() {
  return (
    <div>
        <Nav/>
        <Outlet/>
        <Footer/>
    </div>
  )
}

export default Layout