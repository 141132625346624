import React from 'react'
import { Heroproducts } from '../Components/Heroproducts/Heroproducts'
import { Titleproduit } from '../Components/Titleproduit/Titleproduit'
import { Cards } from '../Components/Cards/Cards'
// import { Cardsone } from '../Components/Cards/Cardsone'
export const Nosproduit = () => {
  return (
    <div>
      <Heroproducts/>
      <Titleproduit/>
      <Cards/>
      {/* <Cardsone/> */}
    </div>
  )
}
