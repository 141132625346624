import React from 'react';
import './Cards.css'
import trmceramique from '../../Assets/trm-ceramique.jpg.webp'
import trmcollier from '../../Assets/trm-collier.webp'
import resistance2 from '../../Assets/resistance2.png'
import TRMplat from '../../Assets/TRM-plat.jpg.webp'
import trmblindee from '../../Assets/trm-blindee.jpg.webp'
import thermoplongeur from '../../Assets/thermoplongeur-trm.jpg.webp'
import trmaillets from '../../Assets/trm-aillets.jpg.webp'
import thermotrm from '../../Assets/thermo-trm.jpg.webp'
import cartouche from '../../Assets/cartouche-trm.jpg.webp'
import barillet from '../../Assets/barillet-trm.jpg.webp'
import trminfrarouge from '../../Assets/trm-infrarouge.jpg.webp'
import infrarouge from '../../Assets/infrarouge.jpg.webp'
import trm from '../../Assets/trm.jpg.webp'
import trmmica from '../../Assets/trm-mica.jpg.webp'
import four from '../../Assets/four.jpg.webp'
import RESISTANCESREFRACTAIRES from '../../Assets/RESISTANCES-REFRACTAIRES.jpg.webp'
import refractaire from '../../Assets/refractaire.jpg.webp'
import regulateur from '../../Assets/regulateur.jpg.webp'
import sonde from '../../Assets/sonde.jpg.webp'
import silicone from '../../Assets/silicone.jpg.webp'
import formable from '../../Assets/formable-1.jpg.webp'
import teflonadhesif from '../../Assets/teflon-adhesif.jpg.webp'
import teflonnonathesif from '../../Assets/teflon-non-athesif.jpg.webp'
import nitto from '../../Assets/nitto.jpg.webp'
import grillage2 from '../../Assets/grillage2.jpg.webp'
import teflofilnickel from '../../Assets/fil-nickel.jpg.webp'
import cable from '../../Assets/cable.jpg.webp'
import gaine from '../../Assets/gaine.jpg.webp'
import prise from '../../Assets/prise.jpg.webp'
import prisef from '../../Assets/prise-f.jpg.webp'
import fiche from '../../Assets/fiche.jpg.webp'
import MATELAS from '../../Assets/MATELAS.jpg.webp'
import fibre from '../../Assets/fibre.jpg.webp'


export const Cards = () => {
  return (
   
    <div className="card-container">
        <div className="card">
          <img src={trmcollier} alt="" />
            <div className="card-content">
              <h3>COLLIER CHAUFFANT MICA BLINDÉ</h3>
              <p> 
              Les colliers chauffants mica blindés sont destinés au chauffage par conduction, de pièces cylindriques. Ils sont utilisés dans l’injection et l’extrusion des matières plastiques ou dans des applications spécifiques comme le chauffage de cuves par l’extérieur. Température maximum d’utilisation des colliers chauffants 350°C, charge maximum 4,2W/cm². Montage : Le diamètre nominal du collier chauffant </p>
              {/* <a href="" className='btun'>ddddddd</a> */}
            </div>
        </div>

        <div className="card">
          <img src={trmceramique} alt="" />
            <div className="card-content">
              <h3>COLLIER CHAUFFANT CÉRAMIQUE BLINDÉ</h3>
              <p>
              Les colliers chauffants en céramique sont destinés au chauffage par conduction, de pièces cylindriques à hautes températures. Ils sont utilisés dans l’injection, l’extrusion des matières plastiques ou dans des applications spécifiques comme le chauffage de cuves par l’extérieur. Température maximum d’utilisation des colliers chauffants 750°C pour le blindage inox et 900°C pour le blindage Inconel. Charge maximum 8 W/cm². </p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>

        <div className="card">
          <img src={TRMplat} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCES CHAUFFANTES PLATE MICA BLINDÉE</h3>
              <p>Les résistances chauffantes plates blindées en mica sont destinées au chauffage par conduction, d’outillages présentant des faces planes. Elles sont utilisées pour chauffer, par l’extérieur, des moules, des plateaux de presse, des étuves, des cuves… Température maximum d’utilisation des résistances chauffantes plates blindées en mica : 350°C, charge maximum 4.2 W/cm².</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={trmblindee} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE CHAUFFANTE TUBULAIRE BLINDÉE</h3>
              <p>Les résistances chauffantes tubulaires blindées sont utilisées dans de nombreuses applications pour le chauffage de liquides et de gaz, par convection et pour le chauffage de solides, par conduction et rayonnement infrarouge. Ce sont des résistances chauffantes polyvalentes. Elles sont généralement formées, elles peuvent être munies de manchons filetés et d’entretoises pour les fixer et les maintenir en position.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={thermoplongeur} alt="" />
            <div className="card-content">
              <h3>THERMOPLONGEUR À VISSER OU SUR BRIDE</h3>
              <p>Les thermoplongeurs sur bride sont destinés au chauffage de liquide par convection. Ils servent à chauffer des liquides dans des cuves ou des fluides en circulation. Ils sont utilisés pour chauffer de l’eau, des huiles, du fuel ou des gaz. </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={trmaillets} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE BLINDÉE À AILETTES</h3>
              <p>
              Les résistances chauffantes tubulaires blindées à ailettes rectangulaires sont utilisées pour le chauffage d’air par convection naturelle ou forcée . On les trouve dans les radiateurs, les batteries, les conduites d’air , les fours et les étuves .Les résistances chauffantes tubulaires blindées à ailettes rectangulaires sont constituées  .
              </p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={thermotrm} alt="" />
            <div className="card-content">
              <h3>RÉCHAUFFEUR EN LIGNE</h3>
              <p>Les réchauffeurs en ligne sont destinés au chauffage de fluides en circulation (liquide ou gaz) jusqu’à 15 bar maxi. Ils sont constitués d’un corps en acier ou en inox avec 1 entrée et une sortie en 1″ Gaz, avec supports, d’un thermoplongeur à visser, d’un thermostat de réglage de la température.</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={cartouche} alt="" />
            <div className="card-content">
              <h3>CARTOUCHE CHAUFFANTE HAUTE CHARGE</h3>
              <p>Les cartouches chauffantes haute charge sont destinées au chauffage, par conduction, d’outillages. Elles sont insérées dans la pièce à chauffer, à l’intérieur d’un alésage H7. On les utilise pour chauffer des moules de presse, des outils de marquage …  </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={barillet} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE CHAUFFANTE SUR BARILLET
              </h3>
              <p>Les résistances chauffantes sur barillets servent au chauffage de liquides. On les trouve typiquement, dans les chauffe-eau ou dans le chauffage de bains spéciaux. Les résistances chauffantes sur barillets sont placées dans des fourreaux et peuvent être remplacées sans vidange de la cuve (conformité vis à vis de la Directives Seveso.

</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={trminfrarouge} alt="" />
            <div className="card-content">
              <h3>ÉMETTEUR INFRAROUGE CÉRAMIQUE
              </h3>
              <p>Chauffage par rayonnement, de produits plats se présentant en couches minces.Le chauffage par infrarouge est utilisé pour ramollir des films plastiques ou des enductions (emballage, thermoformage), pour la cuisson ou le réchauffage d’aliments, le séchage de peinture, colles, vernis, le chauffage de postes de travail.Le temps d’échauffement est rapide (10 minutes), l’irradiation est uniforme et le rendement élevé. </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={infrarouge} alt="" />
            <div className="card-content">
              <h3>ÉMETTEUR INFRAROUGE QUARTZ
              </h3>
              <p>Chauffage par rayonnement de produits plats se présentant en couches minces. Le chauffage par infrarouge est utilisé pour ramollir des films plastiques ou des enductions (emballage, thermoformage), pour la cuisson ou le réchauffage d’aliments, le séchage de peinture, colles, vernis, le chauffage de postes de travail. Le temps d’échauffement est très bref (1 minute), l’irradiation est uniforme et le rendement élevé </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={trm} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE CHAUFFANTE INFRAROUGE SOUS TUBE QUARTZ
              </h3>
              <p>Chauffage par rayonnement de produits plats se présentant en couches minces. Le chauffage par infrarouge est utilisé pour ramollir des films plastiques ou des enductions (emballage, thermoformage), pour la cuisson d’aliments, le séchage de peinture, colles, vernis. Le temps d’échauffement est très bref (1 minute), l’irradiation est uniforme et le rendement élevé. Les résistances chauffantes sous tube quartz RCTQ sont conçues pour être placées à une distance de 100 à 200 mm de la surface à chauffer.

</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={trmmica} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE CHAUFFANTE SOUS PAPIER DE MICA
              </h3>
              <p>Chauffage de surfaces planes par conduction. Les résistances chauffantes sous papier de mica servent à chauffer des plaques de cuissons (gaufrier, crêpières), des têtes d’impression, plateau chauffant de thermoscelleuse, radiateur à inertie avec plaque de pierre, … Température maxi 350°C, charge maxi 2 W/cm². Montage : Les résistances chauffantes mica se placent dans des rainures d’une profondeur légèrement supérieure à l’épaisseur de la résistance (3/10 mm).

</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={four} alt="" />
            <div className="card-content">
              <h3>FOURS INDUSTRIELS
              </h3>
              <p>Nos fours et étuves électriques sont destinés au chauffage sans contact de pièces, jusqu’à 1200°C.Nous étudions, concevons et réalisons des étuves et des installations sur mesure selon cahier des charges. La structure du four est adaptée à la température d’utilisation, aux caractéristiques de la pièce à chauffer (dimensions, matière…), aux cadences de fabrication, permettant ainsi son intégration optimum dans votre chaine de production.</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={RESISTANCESREFRACTAIRES} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCE CHAUFFANTE À FEU VIF
              </h3>
              <p>Les résistances chauffantes à feu vif sont utilisées pour le chauffage, par convection et rayonnement, de fours, d’étuves, de tunnels de cuissons à haute température. Température maximum d’utilisation des résistances à feu vif 900°C. Montage : Les résistances chauffantes plates à feu vif doivent être montées de préférence horizontalement.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={refractaire} alt="" />
            <div className="card-content">
              <h3>BARREAU CHAUFFANT
              </h3>
              <p>Les barreaux chauffants sont utilisés pour le chauffage par convection d’air pulsé à haute température. Température maximum d’utilisation des barreaux chauffants : 900°C.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={regulateur} alt="" />
            <div className="card-content">
              <h3>RÉGULATEUR ÉLECTRONIQUE DE TEMPÉRATURE</h3>
              <p>Les régulateurs électroniques de température permettent de contrôler la température d’une pièce en fonction d’une mesure prise par une sonde (Pt 100, thermocouple). Ils commandent l’alimentation électrique de la résistance par l’intermédiaire d’un contacteur ou d’un bloc thyristor.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={sonde} alt="" />
            <div className="card-content">
              <h3>CAPTEUR DE TEMPÉRATURE : THERMOCOUPLES ET PT100
              </h3>
              <p>Les capteurs de température, sondes PT100 (RTD) et les thermocouples (type K ou J) permettent de mesurer précisément la température. Ils doivent être reliés à un régulateur électronique de température.Les sondes PT100 ont une valeur ohmique qui varie proportionnellement à la température. Elles permettent de mesurer des températures de -100 à 400°C.</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={silicone} alt="" />
            <div className="card-content">
              <h3>ANTI-ADHÉRENT AU SILICONE 400 ML
              </h3>
              <p>Démoulant anti-adhérent de haute performance pour le moulage et le démoulage des matériels thermoplastiques, thermodurcissables et en caoutchouc. Indiqué pour humecter les filets des matrices qui entrent en contact avec des matériaux auto-adhésifs. Hydrofuge, antistatique et lubrifiant sur les matériaux les plus divers.

</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={formable} alt="" />
            <div className="card-content">
              <h3>RÉSISTANCES FORMABLES À FROID
              </h3>
              <p>Les résistances formables à froid, sont des éléments chauffants linéaires de faible section permettant une puissance élevée dans un volume restreint. Elles permettent un chauffage par conduction de pièces de formes variées.Formées à la demande, ces résistances peuvent être livrées droites pour être directement façonnées sur votre machine. Nous disposons, en stock, d’un grand nombre de modèles standards de différentes sections.

</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={teflonadhesif} alt="" />
            <div className="card-content">
              <h3>TEFLON ADHESIF
              </h3>
              <p>TISSU DE VERRE PTFE AVEC ADHÉSIF SILICONE UNE FACE ÉPAISSEUR 8/100EME 1 MÈTRE DE LARGE VENDU AU MÈTRE LINÉAIRETissu de verre PTFE construit avec un adhésif silicone de haute qualité et un tissu de verre en renfort pour offrir des performances mécaniques élevées.</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={teflonnonathesif} alt="" />
            <div className="card-content">
              <h3>TEFLON NON ADHESIF
              </h3>
              <p>Téflon en feuille rouleau non adhèsif 1mx0.13μm, support auto-démoulant, est résistant en haute-température (-73°C à 260°C). Il présente une haute résistance mécanique ainsi qu’une excellente stabilité dimensionnelle. Idéal pour la protection et l’auto démoulage d’un plan de travail pour composition, limiter la friction entre deux matiére, protection des support.

</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={nitto} alt="" />
            <div className="card-content">
              <h3>TEFLON NITTO
              </h3>
              <p>utilise un tissu de verre comme support et imprégné d'une dispersion de polytétrafluoroéthylène (PTFE) puis fritté.Avec un revêtement d'adhésif en silicone, le ruban Nitto a d'excellentes performances en matière de résistance à la chaleur et de résistance chimique.Il a trois types d'épaisseur qui sont 5,12 mil, 5,91 mil et 7,09 mil, le client peut choisir l'épaisseur correspondante en fonction des différentes applications.Doté d'une résistance à la traction et d'une durabilité élevées, Nitto offre d'excellentes performances lorsqu'il est appliqué sur des machines d'emballage et de thermoscellage.

</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={grillage2} alt="" />
            <div className="card-content">
              <h3>FILTRE ACIER INOX
              </h3>
              <p>Le toile métallique est l'une des matières premières indispensable à vos productions industriels de pièces filtrantes ou de filtres métalliques. Nous vous accompagnons et saurons vous fournir le maillage répondant aux impératifs et aux contraintes techniques de votre projet. Nos larges gammes de mailles métalliques tissées vous permettront de trouver la spec. de maille, le matériau, l'ouverture ou le diamètre de passage adapté aux contraintes techniques. Principalemet produits dans les alliages d'inox.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={teflofilnickel} alt="" />
            <div className="card-content">
              <h3>FIL ET RUBAN NICKEL CHROME
              </h3>
              <p>Les fils et les rubans nickel chrome sont composés d’un alliage de 80 % de nickel et 20 % de chrome. Ils sont utilisés pour réaliser des résistances chauffantes, des fils (ou rubans) chauds pour soudeuse ou découpeuse.Température maximum d’utilisation 1200°C, température de fusion 1400°.</p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={cable} alt="" />
            <div className="card-content">
              <h3>CÂBLE HAUTE TEMPÉRATURE
              </h3>
              <p>Les câbles hautes températures sont utilisés pour alimenter ou câbler les appareils électriques qui produisent de la chaleur (résistances électriques, fours…). Ils peuvent être utilisés pour les ambiances chaudes, par exemple à l’intérieur d’étuves. Température maximum d’utilisation des câbles souples, âme nickel isolée soie de verre : 350°C, âme cuivre étamé isolée silicone : 180°C.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={gaine} alt="" />
            <div className="card-content">
              <h3>GAINE HAUTE TEMPÉRATURE
              </h3>
              <p>Les gaines hautes températures sont utilisées pour le câblage de résistances électriques, d’appareils de chauffage et de cuisson. Elles permettent aussi de protéger les câbles situés à proximité d’une source de chaleur par exemple d’un moteur ou d’un point lumineux… Température maximale d’utilisation pour les gaines GV et GDV : 280°C, pour la gaine GS : 250°C.

</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={prise} alt="" />
            <div className="card-content">
              <h3>CONNECTEUR FEMELLE HAUTE TEMPÉRATURE
              </h3>
              <p>Les connecteurs femelles hautes températures servent à fabriquer des câbles d’alimentation pour des résistances ou des appareils électriques (fours, rampe infrarouge, …). Ils peuvent être utilisés pour les ambiances chaudes, par exemple à l’intérieur d’étuves ou sur des colliers chauffants. </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={prisef} alt="" />
            <div className="card-content">
              <h3>CONNECTEUR FEMELLE HAUTE TEMPÉRATURE
              </h3>
              <p>Les connecteurs femelles hautes températures servent à fabriquer des câbles d’alimentation pour des résistances ou des appareils électriques (fours, rampe infrarouge, …). Ils peuvent être utilisés pour les ambiances chaudes, par exemple à l’intérieur d’étuves ou sur des colliers chauffants. Les prises en silicone résistent bien au vieillissement et ne se cassent pas en cas de choc. </p>
              {/* <a href="" className='btun'>ddddddd</a> */}

            </div>
        </div>
        <div className="card">
          <img src={fiche} alt="" />
            <div className="card-content">
              <h3>CONNECTEUR À BROCHES COMPENSÉES POUR THERMOCOUPLE</h3>
              <p>Les connecteurs à broches compensées sont utilisés pour raccorder les thermocouples. Leur utilisation couplée à celle du câble de compensation permet d’éviter la perte de précision engendrée par les connexions électriques sur la mesure de température. Ces connecteurs sont faciles à assembler.</p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={MATELAS} alt="" />
            <div className="card-content">
              <h3>MATELAS D’ISOLATION THERMIQUE
              </h3>
              <p>Ces matelas servent à isoler les colliers chauffants et les résistances plates. Ils permettent de réaliser des économies d’énergie et préviennent les risques de brûlure en réduisant la température extérieure des outillages. Ils sont fabriqués sur mesure et équipés de sangles ou de bandes velcro pour un montage et un démontage faciles. </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>
        <div className="card">
          <img src={fibre} alt="" />
            <div className="card-content">
              <h3>NAPPE ISOLANTE EN FIBRE CÉRAMIQUE
              </h3>
              <p>Nappe en fibres longues aiguilletées sur chaque face, facile à découper, à manipuler et faiblement irritante. Ces nappes de fibres isolantes sont vendues en rouleaux de diverses épaisseurs et servent à calorifuger des cheminées, des fours, des écrans thermiques. Elles sont particulièrement adaptées aux hautes températures, jusqu’à 1200°C en pointe. </p>
              {/* <a href="" className='btun'>payer</a> */}

            </div>
        </div>

    </div>
 
    
  );
};
