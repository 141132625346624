import React from 'react'
import './Heroproducts.css'
// import heroimgg from '../../Assets/bgproducts.jpg'
import bgproducts from '../../Assets/bgg-product.jpg'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { useEffect } from 'react'

export const Heroproducts = () => {
  useEffect(()=>{
    AOS.init({
      duration:2000
    });
  },[]);
  return (
    <div>
     <section id="heroo">
        <div class="hero-contentt" data-aos='fade-in'>
            <h1 className='titrede'>NOS PRODUITS</h1>
            {/* <p>Powering Your Future with Clean Energy</p> */}
        </div>
    </section>


    </div>
  )
}
