import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import resispic from '../../Assets/nasserlogo.png';
import "./Nav.css";

const NavBar = () => {
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function scrollHandler() {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Navbar
      fixed="top"
      expand="md"
      className={isFixed ? "navbar fixed" : "navbar"}
    >
      <Container className="navbar-container">
        <Navbar.Brand as={Link} to="/">
          <ion-icon name="bag"></ion-icon>
          <img src={resispic} className="resispic" alt="Brand Logo" />
        </Navbar.Brand>
        <div className="d-flex">
          <Navbar.Toggle
            className="options"
            aria-controls="basic-navbar-nav"
            onClick={() => setExpand(expand ? false : "expanded")}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Item>
              <Link
                aria-label="Go to Home Page"
                className="navbar-link"
                to="/Accueil"
                onClick={() => setExpand(false)}
              >
                <span id="nva" className="nav-link-label">Accueil</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Shop Page"
                className="navbar-link"
                to="/Nos produit"
                onClick={() => setExpand(false)}
              >
                <span id="nva" className="nav-link-label">Nos produit</span> 
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Contact Page"
                className="navbar-link"
                to="/Notre histoire"
                onClick={() => setExpand(false)}
              >
                <span id="nva" className="nav-link-label">Notre histoire</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Help Page"
                className="navbar-link"
                to="/Contact"
                onClick={() => setExpand(false)}
              >
                <span id="nva" className="nav-link-label">Contact</span>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
