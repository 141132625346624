import React from 'react';
import './Textimg.css';
import chaufante1 from '../../Assets/chaufante1.jpg'
import chaufante3 from '../../Assets/chaufante3.jpg'
import chaufante4 from '../../Assets/chaufante4.jpg'
import { Link } from "react-router-dom";
import bgproducts from '../../Assets/bgproducts.jpg'
import imgfore from '../../Assets/imgfore.jpg'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import AOS from 'aos'


export const Textimg = () => {
  return (
    <div className="containerr">
      <div className="contentLeft">
        <div className="row">
          <div className="imgWrapper">
            <img src={chaufante4} alt="Image 1" data-aos='fade-up'/>
          </div>
          <div className="imgWrapper">
            <img src={chaufante3} alt="Image 2"data-aos='fade-up' />
          </div>
          <div className="imgWrapper">
            <img src={bgproducts} alt="Image 3" data-aos='fade-up'/>
          </div>
          <div className="imgWrapper">
            <img src={imgfore} alt="Image 4"data-aos='fade-up' />
          </div>
        </div>
      </div>
      <div className="contentRight"data-aos='fade-left'>
        <div className="content">
          <h4> La solution à vos besoins en résistances chauffantes électriques, pour liquide, air, gaz ou solide.</h4>
          <h2>
          NOUS AVONS VOTRE SOLUTION DE CHAUFFAGE</h2>
          <p>
          Quelque soit votre secteur d’activité, nous définirons la solution adaptée à votre besoin de chauffage.

Lignes de traitement de surface, constructeurs de presses chauffantes, professionnels des métiers de bouche, industriels à process chauffant pour solides, liquides, ou gaz…quand c’est chaud…

La large gamme de matériel de résistances électriques chauffantes sous formes de thermoplongeurs, d’éléments blindés, de câbles, de bâches, de cartouches chauffante, de barillets …nous permettra de réaliser votre élément sur mesure ou à partir de composants standards.


          </p>
          <Link to='/Notre histoire'>plus sur nous...</Link>
        </div>
      </div>
    </div>
  );
};
