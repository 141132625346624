import React from 'react';
import './About.css';
import resistance3 from '../../Assets/resistance3.png'
import resistance2 from '../../Assets/resistance2.png'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const About = () => {
  useEffect(()=>{
    AOS.init({
      duration:2000
    });
  },[]);
  return (
    <div>
      {/* <img className='coverr' src={resis5} alt="" /> */}
    <section id="hero" >
        <div class="hero-content">
            <h1 className='herotitle' data-aos='fade-in'>NOTRE HISTOIRE</h1>
            {/* <p>Powering Your Future with Clean Energy</p> */}
        </div>
    </section>

 

   

    <section id="services">
        <h2>Our Services</h2>
        <div class="service" data-aos='fade-in'>
              
            <p>Présente depuis 2006, TECHNIQUE RESISTANCES MAROC conçoit et fabrique des éléments chauffants électriques qui répondront à vos besoins industriels les plus variés, le tout avec un maximun de flexibilité et de qualité, des livraisons rapides et précises et une assistance technique avant et après-vente.

Concrétiser les souhaits de ses clients en leur apportant des solutions thermiques efficaces et fiables, proposer une gamme de produits diversifiés standards et sur mesure permettant des applications variées et spécifiques, voilà vers quoi l’entreprise concentre ses efforts.</p>
       <img className='picabout1' src={resistance3} alt="" />
        </div>
    
    </section>

    <section id="sustainability">
        <h2 className='choisir'>Choisissez nos produits, et bénéficiez :</h2>
        
        {/* <p>We are dedicated to reducing our carbon footprint and promoting green energy solutions.</p> */}
        <ul>
            <li>- Du fort savoir-faire des spécialites de l'électrothermie</li>
            <li>
            - De la grande diversité des produits adaptés à vos besoins</li>
            <li>
              
D'un rapport qualité/prix très compétitif
            </li>
            <li>- D'une livraison des commandes dans les meilleurs délais</li>
            <li>- D'un service après-vente professionnel</li>
        </ul>
    </section>
   
   
    <section id="partnerships">
       
    <h2>Our Services</h2>
        
            <h3>Residential Electricity</h3>
            <p className='secondtext'>TECHNIQUE RESISTANCES MAROC a pour mission de devenir l’entreprise incontournable pour la fourniture d’éléments chauffants électriques, grâce à l’engagement de son personnel, à la satisfaction de sa clientèle ainsi qu’à l’innovation et à la qualité de ses produits.
            Nous nous engageons à fournir des produits et des services conformes aux attentes et exigences les plus spécifiques, à assurer l’amélioration continue de la performance des produits en adoptant une stratégie de gestion ouverte sur l’environnement commercial et technologique.</p>
       <img className='picabout2' src={resistance2} alt="" />
       
    </section>


    </div>
  );
}

export default About;
